import React from "react";

function Trusted() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="text-center pt-5 ">
            <img src="./img/haha.png" className="pb-4" />
            <h1 className="gradient"> Trusted by world class operators, </h1>
            <h1 className="gradient pb-5 mb-4">investors and institutions</h1>
          </div>

          <div class="text-center ">
            <div class="row">
              <div class="col-lg-3 col-md-3">
                <img src="./img/3.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/2.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/1.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/4.png" className="w-75 pt-3" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3">
                <img src="./img/8.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/5.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3 pt-4">
                <img src="./img/10.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/11.png" className="w-75 pt-3" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-3 col-md-3">
                <img src="./img/6.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/3.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/7.png" className="w-75 pt-3" />
              </div>
              <div class="col-lg-3 col-md-3">
                <img src="./img/9.png" className="w-75 pt-3" />
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5 mt-5 ">
            <div className="col-md-6 ">
              <img src="./img/design-3.png" className="des_ign" />
            </div>
            <div className="col-md-6">
              <div className="Power_ful md:mt-0 mt-4 md:text-start ">
                <h1 className="gradient">
                  Powerful
                  <br />
                  Infrastructure
                </h1>
              </div>
              <div className="row  pt-4 pb-3 text Power_ful">
                <div className="col-md-6">
                  <h4 className="text-white">Unparalleled speed</h4>
                </div>
                <div className="col-md-6 pr_a">
                  <p className="text-white">
                    The fastest blockchain in the industry, unlocked by Twin
                    Turbo Consensus. All with low transaction costs and industry
                    leading throughput.
                  </p>
                  <p className="text-white">
                    Modern applications require modern performance and
                    scalability.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 d-flex pt-4 pb-3 text Power_ful">
                <div className="col-6">
                  <h4 className="text-white">Security-First</h4>
                </div>
                <div className="col-lg-6 col-md-6 pr_a">
                  <p className="text-white">
                    Secured by some of the largest institutions in the world,
                    Sei is built from the ground up to prioritize security above
                    all else.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 d-flex pt-4 pb-3 text ">
                <div className="col-lg-6 col-md-6">
                  <h4 className="text-white">User centric</h4>
                </div>
                <div className="col-lg-6 col-md-6 pr_a">
                  <p className="text-white">
                    With a deep commitment to fairness, Sei is fully open source
                    and offers native frontrunning protection, seamless
                    interoperability, and multiple levels of transaction
                    bundling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="back_color">
        <div className="container">
          <h1 className="gradient  text-center pt-5  ">
            Comparing to Other Blockchains
          </h1>
          <div className="row  pt-5 pb-5">
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Bitcoin</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      Transactional Throughput
                    </h6>
                    <h6 className="pb-3 fs-5">10tps</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">60min</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="pb-5">Proof of Work</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Ethereum</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      Transactional Throughput
                    </h6>
                    <h6 className="pb-3 fs-5">10tps</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">60min</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="pb-5">Proof of Work</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Solana</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      Transactional Throughput
                    </h6>
                    <h6 className="pb-3 fs-5">10tps</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">60min</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="pb-5">Proof of Work</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text md:mt-0 mt-4">
              <div className="border pt-2 rounded-3">
                <div className="">
                  <div className="border-bottom">
                    <h5 className=" pb-1 text-white p-4">Blackbit</h5>
                  </div>
                  <div className=" text-white p-4">
                    <h6 className="small text-lite">
                      Transactional Throughput
                    </h6>
                    <h6 className="pb-3 fs-5">10tps</h6>
                    <h6 className="small text-lite">Transactional Finality</h6>
                    <h6 className="pb-3 fs-5">60min</h6>
                    <h6 className="small text-lite">Frontrunning prevention</h6>
                    <h6 className="pb-3 fs-5">None</h6>
                    <h6 className="small text-lite">Processing style</h6>
                    <h6 className="pb-3 fs-5">Sequential</h6>
                    <h6 className="small text-lite">Sybil Protection</h6>
                    <h6 className="">Proof of Work</h6>
                  </div>
                  <button type="button" class="btn text-white  btn-lg  STA_RT">
                    Start Building
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-5 pb-5  mt-5  ">
          <div className="col-lg-12">
            <h1 className="gradient text-center mb-4" >
              Who s Building on Blackbit?
            </h1>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 d-flex justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/21.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text text-white p-3">
                      Axelar <br /> Infrastructure
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/22.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text  text-white pt-3">
                      Pyth <br /> Oracle
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/23.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text  text-white pt-3">
                      Sushi <br /> DeFi
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/24.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text text-white  pt-3">
                      Kargo  Prediction<br /> Markets
                    </h6>
                  </div>
                  <div className="col-lg-2 col-md-2 text-center ">
                    <img
                      src="./img/25.png"
                      className="rounded-pill w-50 text-center mt-5"
                    />
                    <h6 className="text text-white  pt-3">
                      Nitro <br /> Infrastructure
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 ">
            <div className="col-lg-12 text-center">
              <button
                type="button"
                class="btn text-white  btn-lg mt-5 mb-4 STA_RTb   "
              >
                Start Building
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5  ">
          <div className="col-lg-6">
            <img src="./img/27.png" className="Bloc_kchain pt-5 mt-5" />
          </div>
          <div className="col-lg-6">
            <h1 className="gradient pb-5 m-3">
              A New Blockchain <br /> Design Space
            </h1>
            <div className="accordion custom-accordion bg-transparent border-0 border-bottom-1">
              <div className="accordion-item bg-transparent border-0 border-bottom-1 ">
                <h2 className="accordion-header " id="headingOne">
                  <button
                    className="accordion-button bg-transparent text-white border-0 border-bottom-1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Highly Secure
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse  text-white border-0 border-bottom-1"
                  aria-labelledby="headingOne"
                  data-parent=".accordion"
                >
                  <div class="accordion-body">Accordion content for row 1.</div>
                </div>
              </div>

              <div className="accordion-item bg-transparent border-0 border-bottom-1 mt-4">
                <h2 className="accordion-header " id="headingTwo">
                  <button
                    className="accordion-button bg-transparent text-white border-0 border-bottom-1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Massively Scalable
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse  bg-transparent text-white border-0"
                  aria-labelledby="headingTwo"
                  data-parent=".accordion"
                >
                  <div className="accordion-body">
                    Accordion content for row 2.
                  </div>
                </div>
              </div>

              <div className="accordion-item bg-transparent border-0 border-bottom-1  mt-4">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button bg-transparent border-0 border-bottom-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Engineered to Evolve
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse bg-transparent border-0 border-bottom-1 text-white"
                  aria-labelledby="headingThree"
                  data-parent=".accordion"
                >
                  <div class="accordion-body">Accordion content for row 3.</div>
                </div>
              </div>

              <div className="accordion-item bg-transparent border-0 border-bottom-1 text-white mt-4">
                <h2 className="accordion-header " id="headingFour">
                  <button
                    className="accordion-button bg-transparent border-0 border-bottom-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    The Fastest Blockchain
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse "
                  aria-labelledby="headingFour"
                  data-parent=".accordion"
                >
                  <div className="accordion-body ">
                    Accordion content for row 4.
                  </div>
                </div>
              </div>

              <div className="accordion-item bg-transparent border-0 border-bottom-1  mt-4">
                <h2 className="accordion-header " id="headingFive">
                  <button
                    className="accordion-button bg-transparent border-0 border-bottom-1  text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Brand New Applications
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse bg-transparent border-0 border-bottom-1 text-white"
                  aria-labelledby="headingFive"
                  data-parent=".accordion"
                >
                  <div className="accordion-body">
                    Accordion content for row 5.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Trusted;
