import React from 'react'

function Counter() {
    return (
        <div>

            <div className="back_color">
                <div className="container">
                    <div className="row  text-center p-3 mb-5">
                        <div className="col-lg-3 col-md-3">
                            <div className="col-12">
                                <h4 className="counter gradient-text">380ms</h4>
                            </div>
                            <div className="col">
                                <h4 className="text mb-5 pb-2 gradient-text text-white">
                                    Transaction Finality
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="col">
                                <h4 className="counter gradient-text">20,000</h4>
                            </div>
                            <div className="col">
                                <h4 className="text mb-5 pb-2 gradient-text text-white">
                                    Orders par second
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="col">
                                <h4 className="counter gradient-text">100m+</h4>
                            </div>
                            <div className="col">
                                <h4 className="text mb-5 pb-2 gradient-text text-white">
                                    Total Testnet Transactions
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="col">
                                <h4 className="counter gradient-text">4m</h4>
                            </div>
                            <div className="col">
                                <h4 className="text mb-5 pb-2 gradient-text text-white">Testnet Users</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Counter