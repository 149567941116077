import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Roadmap() {

    const steps = [
        { title: 'Step 1', description: 'Event description for Step 1' },
        { title: 'Step 2', description: 'Event description for Step 2' },
        { title: 'Step 3', description: 'Event description for Step 3' },
        // Add more steps as needed
    ];
    return (<>
        <Header />
        <div className="historical-steps rodemap_bg mt-5 pt-5 pb-5 mb-5">
            <div class="container">
                <div class="row text-center justify-content-center mb-5  mt-5 ">
                    <div class="col-xl-6 col-lg-8">
                        <h2 class="font-weight-bold gradient">A Company RodeMap</h2>
                        <p class="text-white ">We’re very proud of the path we’ve taken. Explore the history that made us the company we are today.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col  pb-5">
                        <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase1</h3>
                                    <p class="h6 mt-3 mb-1">2003</p>
                                    <p class="h6  mb-0 mb-lg-0">Favland Founded</p>
                                </div>
                            </div>
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase2</h3>
                                    <p class="h6 mt-3 mb-1">2004</p>
                                    <p class="h6  mb-0 mb-lg-0">Launched Trello</p>
                                </div>
                            </div>
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase3</h3>
                                    <p class="h6 mt-3 mb-1">2005</p>
                                    <p class="h6  mb-0 mb-lg-0">Launched Messanger</p>
                                </div>
                            </div>
                            <div class="timeline-step">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase4</h3>
                                    <p class="h6 mt-3 mb-1">2010</p>
                                    <p class="h6  mb-0 mb-lg-0">Open New Branch</p>
                                </div>
                            </div>
                            <div class="timeline-step mb-0">
                                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                    <div class="inner-circle"></div>
                                    <h3 class="mt-3 mb-1">Phase5</h3>
                                    <p class="h6 mt-3 mb-1">2020</p>
                                    <p class="h6  mb-0 mb-lg-0">In Fortune 500</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
    );
}

export default Roadmap;
