import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark  header_bg py-3">
      <div class="container">
        <Link className="Logo" to="/"><img src="./img/logo.png" className="w-100" /></Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link to='/' class="nav-link active">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Comingsoon' class="nav-link" >
                BLOCKS
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Comingsoon' class="nav-link" >
                DeFi
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Comingsoon' class="nav-link" >
                Dex
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Roadmap' class="nav-link" >
                ROADMAP
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Tokenomics' class="nav-link" >
                TOKENOMICS
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Comingsoon' class="nav-link" >
                whitepaper
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Comingsoon' class="nav-link" >
                BlackBit-Pay
              </Link>
            </li>
            <li class="nav-item">
              <Link to='/Comingsoon' class="nav-link" >
                TRANSACTION
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
