import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import Tokenomics from './componet/Tokenomics';
import Roadmap from './componet/Roadmap';
import Web3 from 'web3';
import Comingsoon from './componet/Comingsoon';
function initWeb3() {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    return web3;
  } else {
    console.error("MetaMask provider not available");
    // Handle the situation when MetaMask provider is not available
    return null;
  }
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage initWeb3={initWeb3} />} />
        <Route path="/HomePage" element={<HomePage initWeb3={initWeb3}/>} />
        <Route path="/Tokenomics" element={<Tokenomics />} />
        <Route path="/Roadmap" element={<Roadmap />} />
        <Route path="/Comingsoon" element={<Comingsoon />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;