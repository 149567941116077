import React from 'react';


function Footer() {
  return (
    <div className="full_color pt-5 pb-4">
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-4 ">
           <div className='footer_logo'><img src="./img/logo.png" alt="" className='w-100 img-fluid' /></div> 
           
          </div>
        <div className="col-md-4">
          <div className="text-light text-center fs-5">
            <p href="#">Home</p>
            <p href="#">About</p>
            <p href="#">Contact</p>
            <p href="#">Feature</p>
            </div>
        </div>
        <div className="col-md-4">
          <div className="text-light text-center fs-5">
            <p href="#">Blog</p>
            <p href="#">Media</p>
            <p href="#">jobs</p>
            <p href="#">Feature</p>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
