import React from 'react'

function NewsSlider() {
    return (
        <div>
            <div class="container">

                <div className='row'>
                    <div className='col-12 py-4'>
                        <h1 className='gradient'>
                            Featured News
                        </h1>
                    </div>
                </div>
                <div id="carouselExample" class="carousel slide  pb-5" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-md-4 col-12">
                                    <div className="card m-2 pt-0 rounded-3 bg-dark">
                                        <img
                                            src="./img/32.png"
                                            className="card-img-top w-100  rounded"
                                            alt="Card 4"
                                        />
                                        <div className="card-body">
                                            <p className="card-text text-white">
                                                Foresight Ventures Brings Sei Ecosystem Fund to $120
                                                Million
                                            </p>
                                            <p className="card-text">
                                                <small className="text-white">BLOOMBERG</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div className="card m-2 pt-0 rounded-3 bg-dark">
                                        <img
                                            src="./img/33.png"
                                            className="card-img-top w-100  rounded"
                                            alt="Card 4"
                                        />
                                        <div className="card-body">
                                            <p className="card-text text-white">
                                                Foresight Ventures Brings Sei Ecosystem Fund to $120
                                                Million
                                            </p>
                                            <p className="card-text">
                                                <small className="text-white">BLOOMBERG</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div className="card m-2 pt-0 rounded-3 bg-dark">
                                        <img
                                            src="./img/34.png"
                                            className="card-img-top w-100  rounded"
                                            alt="Card 4"
                                        />
                                        <div className="card-body">
                                            <p className="card-text text-white">
                                                Foresight Ventures Brings Sei Ecosystem Fund to $120
                                                Million
                                            </p>
                                            <p className="card-text">
                                                <small className="text-white">BLOOMBERG</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-md-4 col-12">
                                    <div className="card m-2 pt-0 rounded-3 bg-dark">
                                        <img
                                            src="./img/32.png"
                                            className="card-img-top w-100  rounded"
                                            alt="Card 4"
                                        />
                                        <div className="card-body">
                                            <p className="card-text text-white">
                                                Foresight Ventures Brings Sei Ecosystem Fund to $120
                                                Million
                                            </p>
                                            <p className="card-text">
                                                <small className="text-white">BLOOMBERG</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div className="card m-2 pt-0 rounded-3 bg-dark">
                                        <img
                                            src="./img/33.png"
                                            className="card-img-top w-100  rounded"
                                            alt="Card 4"
                                        />
                                        <div className="card-body">
                                            <p className="card-text text-white">
                                                Foresight Ventures Brings Sei Ecosystem Fund to $120
                                                Million
                                            </p>
                                            <p className="card-text">
                                                <small className="text-white">BLOOMBERG</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12">
                                    <div className="card m-2 pt-0 rounded-3 bg-dark">
                                        <img
                                            src="./img/34.png"
                                            className="card-img-top w-100  rounded"
                                            alt="Card 4"
                                        />
                                        <div className="card-body">
                                            <p className="card-text text-white">
                                                Foresight Ventures Brings Sei Ecosystem Fund to $120
                                                Million
                                            </p>
                                            <p className="card-text">
                                                <small className="text-white">BLOOMBERG</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                   <div className='mt-4'>
                   <a class="" href="#carouselExample" role="button" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </a>
                    <a class="" href="#carouselExample" role="button" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </a>
                   </div>
                </div>
            </div>

        </div>
    )
}

export default NewsSlider