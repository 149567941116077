import logo from './logo.svg';
import './App.css';
import Header from './componet/Header';
import Banners from './componet/Banners';
import Counter from './componet/Counter';
import Trusted from './componet/Trusted';
import Footer from './componet/Footer'
import NewsSlider from './componet/NewsSlider';

function HomePage({ initWeb3 }) {
  return (
    < >
      <Header />
      <Banners  initWeb3={initWeb3} />
      <Counter />
      <Trusted />
      <NewsSlider/>
      <Footer />
    </>
  );
}

export default HomePage;
